import { Controller } from "@hotwired/stimulus";

import "mapbox-gl/dist/mapbox-gl.css";
import "stylesheets/app/catalog/search.scss";

export default class extends Controller {
  static values = { mapboxToken: String, mapCardPrototype: String, fetchUrl: String };

  static targets = ["map", "mapContainer", "mapLoader", "lots"];

  mapboxgl = null;

  _formFilter = null;
  _isMapOpen = false;
  _markers = [];
  _refreshOnMove = false;
  _loading = false;
  _ready = false;

  initialize() {
    this.element[this.identifier] = this;

    import("!mapbox-gl").then(
      ((mapboxgl) => {
        this.mapboxgl = mapboxgl.default;
        this.initMap();
      }).bind(this)
    );
  }

  initMap() {
    this.mapboxgl.accessToken = this.mapboxTokenValue;
    this._map = new this.mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [2.2508, 47.1539],
      zoom: 4,
    });

    this._map.on(
      "load",
      (() => {
        this.resizeMap();
        this._map
          .on("click", () => {
            this.clearMarkerCards();
          })
          .on(
            "moveend",
            this.debounce((e) => {
              if (this._refreshOnMove && !this._loading) this.reloadResults();
              this._loading = false;
            })
          );
        this.refreshResults(true);
        this._ready = true;
      }).bind(this)
    );
  }

  toggleMap() {
    this._isMapOpen = !this._isMapOpen;
    if (this._isMapOpen) {
      this.element.classList.add("full-map");
    } else {
      this.element.classList.remove("full-map");
    }
    this.resizeMap();
    this.refreshResults(true);
  }

  addMarker(lot) {
    const mapMarker = document.createElement("div");
    mapMarker.dataset.id = lot.public_uid;
    mapMarker.className = "map-marker";
    if (lot.dispo_status == "busy") {
      mapMarker.innerHTML =
        "" +
        "<svg xmlns=\"http://www.w3.org/2000/svg\" stroke='currentColor' height='24' width='24' fill=\"none\" viewBox=\"0 0 24 24\">\n" +
        '  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />\n' +
        "</svg>";
    } else {
      mapMarker.innerText = lot.encadrement_loyer_cc ? `${parseInt(lot.encadrement_loyer_cc)}€` : "- €";
    }
    mapMarker.addEventListener(
      "click",
      ((e) => {
        this.openMarker(e, lot);
      }).bind(this)
    );
    let marker = new this.mapboxgl.Marker({
      element: mapMarker,
    })
      .setLngLat([lot.property.longitude, lot.property.latitude])
      .addTo(this._map);
    this._markers.push(marker);
    return marker;
  }

  clearMarkerCards() {
    document.querySelectorAll(".map-marker").forEach((e) => {
      e.classList.remove("active");
      e.style.zIndex = "10";
    });
    // Delete all previous cards
    document.querySelectorAll(".lot-card").forEach((e) => {
      e.style.display = "none";
      e.style.zIndex = "10";
    });
  }

  openMarker(event, lot) {
    event.stopPropagation();
    this.clearMarkerCards();

    const marker = event.currentTarget;
    marker.classList.add("active");
    marker.style.zIndex = "30";
    const parent = marker;
    let markerBounding = marker.getBoundingClientRect();
    let top = markerBounding.height + 10;
    let left = -190 + markerBounding.width / 2;
    let markerCard = document.querySelector('[data-id="lot_card_' + lot.public_uid + '"]');
    markerCard.style.position = "absolute";
    markerCard.style.top = `${top}px`;
    markerCard.style.left = `${left}px`;
    markerCard.style.display = "flex";
    markerCard.style.zIndex = "30";
    marker.appendChild(markerCard);
  }

  refreshResults(fitBounds = false) {
    this._markers.forEach((marker) => {
      marker.remove();
    });
    this._markers = [];

    let bounds = null;

    if (!this.hasLotsTarget) return;
    this._lots = JSON.parse(this.lotsTarget.dataset.lots).reverse();
    if (this._lots.length > 0) {
      bounds = new this.mapboxgl.LngLatBounds();
      this._lots.forEach(
        ((lot) => {
          let marker = this.addMarker(lot);
          bounds.extend(marker.getLngLat());
        }).bind(this)
      );
    }

    // Get bounds from query params if existing
    const urlParams = new URLSearchParams(decodeURI(window.location.search));
    let bboxParam = urlParams.get("bbox");
    if (bboxParam) {
      bboxParam = bboxParam.split(",").map((n) => parseFloat(n));
      bounds = new this.mapboxgl.LngLatBounds([bboxParam[1], bboxParam[2]], [bboxParam[3], bboxParam[0]]);
    }

    if (bounds) {
      // if list displayed then bbox is half width centered (-25% each side along X)
      let bbox = bounds.toArray();

      if (!this._isMapOpen) {
        let diffLng = ((bbox[1][0] - bbox[0][0]) * 25) / 100;
        bbox[1][0] += diffLng;
        bbox[0][0] -= diffLng;
      }

      // Add 10% padding so markers are well visible
      let paddingLng = ((bbox[1][0] - bbox[0][0]) * 10) / 100;
      let paddingLat = ((bbox[1][1] - bbox[0][1]) * 10) / 100;

      bbox[0][0] -= paddingLng;
      bbox[0][1] -= paddingLat;
      bbox[1][0] += paddingLng;
      bbox[1][1] += paddingLat;

      bounds = new this.mapboxgl.LngLatBounds(bbox[0], bbox[1]);

      // Resize & zoom to fit all markers on map
      if (fitBounds == true && bounds) {
        this._loading = true;
        this._map.fitBounds(bounds, { padding: 100 });
      }
    }
    this._loading = false;
    this.element.classList.remove("loading");
  }

  reloadResults() {
    this.loading();
    // Calculate visible bounding box
    const bounds = this._map.getBounds();
    let bbox = [bounds.getNorthWest().toArray().reverse(), bounds.getSouthEast().toArray().reverse()];

    // if list displayed then bbox is half width centered (-25% each side along X)
    if (!this._isMapOpen) {
      let diffLng = ((bbox[1][1] - bbox[0][1]) * 25) / 100;
      bbox[1][1] = bbox[1][1] - diffLng;
      bbox[0][1] = bbox[0][1] + diffLng;
    }

    // Add 10% padding so markers are well visible
    let paddingLng = ((bbox[1][1] - bbox[0][1]) * 10) / 100;
    let paddingLat = ((bbox[0][0] - bbox[1][0]) * 10) / 100;

    bbox[0][0] -= paddingLat;
    bbox[0][1] += paddingLng;
    bbox[1][0] += paddingLat;
    bbox[1][1] -= paddingLng;

    this._formFilter = document.querySelector("form.filters");
    let bboxInput = this._formFilter.querySelector('[name="bbox"]');
    bboxInput.value = bbox;
    let queryInput = this._formFilter.querySelector('[name="query"]');
    queryInput.value = "";
    this._formFilter.requestSubmit();
  }

  resizeMap() {
    this._map.resize();
  }

  toggleRefreshOnMove(e) {
    e.preventDefault();
    e.stopPropagation();
    let elem = e.currentTarget;
    this._refreshOnMove = !this._refreshOnMove;
    if (this._refreshOnMove) {
      elem.classList.add("active");
      this.reloadResults();
    } else {
      elem.classList.remove("active");
    }
  }

  zoom(e) {
    if (this._map) {
      const zoomType = e.currentTarget.dataset.zoomType;
      const currentZoom = this._map.getZoom();
      const targetZoom = zoomType === "in" ? currentZoom + 1 : currentZoom - 1;
      this._map.zoomTo(targetZoom);
    }
  }

  highlightMarker(e) {
    // Clear all other markers just in case
    const lot_id = e.currentTarget.dataset.id;
    const marker = document.querySelector('.map-marker[data-id="' + lot_id + '"]');
    if (marker) {
      marker.classList.add("active");
      marker.style.zIndex = "30";
    }
  }

  unhighlightMarker(e) {
    const lot_id = e.currentTarget.dataset.id;
    const marker = document.querySelector('.map-marker[data-id="' + lot_id + '"]');
    if (marker) {
      marker.classList.remove("active");
      marker.style.zIndex = "10";
    }
  }

  loading() {
    this.element.classList.add("loading");
    let lotList = document.querySelector(".lots-list");
    if (lotList) lotList.innerHTML = "";
  }

  debounce(func) {
    var timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, 200, event);
    };
  }
}
